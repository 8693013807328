@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ruda:wght@400..900&display=swap');
@font-face {
  font-family: 'RubikOne';
  src: url('./assets/font/RubikOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .list-item-orange::before {
    content: '•'; 
    color: #FB923C; 
    margin-right: 0.5rem; 
    display: inline-block;  
   }
}


.text-titles { /*revisa porfi*/
  @apply 
  3xl:text-[4.5rem]
  max-2xl:text-[2.62rem]
  xl:text-[3.25rem]
  lg:text-[3.25rem]
  mdf:text-[2.25rem]
  max-md:text-[2rem]
  max-sm:text-[1.5rem]
  font-normal
  text-[#173D73]
  uppercase
  font-rubik
}
.text-titles-segundo { /*revisa porfi*/
  @apply 
  3xl:text-[4.5rem]
  max-2xl:text-[2rem]
  xl:text-[3rem]
  lg:text-[2rem]
  md:text-[1rem]
  max-sm:text-[1rem]
  font-normal
  text-[#173D73]
  uppercase
  font-rubik
}
.text-titles-jornada { /*revisa porfi*/
  @apply 
  3xl:text-[4.5rem]
  max-2xl:text-[2rem]
  xl:text-[3rem]
  lg:text-[2rem]
  md:text-[1rem]
  max-sm:text-[1rem]
  font-normal
  text-[#173D73]
  uppercase
  font-rubik
}
.text-title-menu {
  @apply 
  3xl:text-[2rem] 
  2xl:text-[1.35rem] 2xl:w-[16.1rem]
  xl:text-[1.25rem] xl:w-[15.1rem]
  mdf:text-[1rem] mdf:w-[14.1rem]
  text-white
  uppercase
  font-rubik
  /*max-mdf:w-full max-mdf:bg-[#173D73] max-mdf:p-0
  max-md:text-[2rem]
  font-normal
  text-[#fff]
  */
}
.text-title-header {
  @apply 
  3xl:text-[8.6rem]
  2xl:text-[5.25rem] 
  xl:text-[3.25rem]
  lg:text-[2.35rem]
  mdf:text-[2.75rem]
  md:text-[4.37rem] 
  max-md:text-[2.37rem]
  font-normal 
  text-[#173D73] 
  uppercase 
  font-rubik
}
.text-title-header-informe {
  @apply 
  3xl:text-[5.37rem] 
  2xl:text-[2.37rem] 
  lg:text-[1.8rem] 
  mdf:text-[2.37rem] 
  max-mdf:text-[1.75rem] 
  font-extrabold 
  text-[#173D73] 
  font-ruda
}
.text-title_lanza {
  @apply 
  3xl:text-[4rem]
  2xl:text-[2.25rem] 
  md:text-[2rem] md:text-center
  max-md:text-[1.37rem] 
  font-normal 
  text-[#173D73] 
  font-rubik
}
.text-title-pilares {
  @apply 
  3xl:text-[2.25rem]
  2xl:text-[2rem] 
  md:text-[1.25rem] 
  max-md:text-[1rem] 
  font-normal 
  text-[#173D73] 
  uppercase 
  font-rubik
}
.text-title-nuestrosp {
  @apply 
  3xl:text-[3rem]
  2xl:text-[2rem]
   md:text-[1.62rem] 
   max-md:text-[1.37rem] 
   font-black 
   text-[#4E4E4E]  
   font-ruda
}

.text-video-white {
  @apply 
  3xl:text-[2.6rem]
  2xl:text-[2rem]
  mdf:text-[1.3rem] 
  max-mdf:text-[1.37rem] 
  font-normal 
  text-white
  font-rubik
}
.text-title-txt {
  @apply 
  3xl:text-[3rem]
  2xl:text-[2.37rem] 
  md:text-[1.5rem] 
  max-md:text-[1.06rem] 
  font-extrabold 
  text-[#fff]  
  font-ruda
}
.text-title-sub {
  @apply
  3xl:text-[2.62rem]  
  2xl:text-[1.62rem] 
  md:text-[1.37rem] 
  max-md:text-[1.12rem] 
  max-md:text-center 
  font-normal 
  text-[#fff]  
  font-rubik
}
.texts {
  @apply 
  3xl:text-[3.3rem]
  2xl:text-[2.5rem] 
  mdf:text-[1.37rem] 
  max-md:text-[1.06rem] 
  /*max-md:text-center */
  font-bold 
  text-[#313131]  
  font-lato
}
.textsSegundo {
  @apply 
  3xl:text-[2.3rem]
  2xl:text-[1.5rem] 
  md:text-[1.2rem] 
  max-md:text-[.8rem] 
  font-bold 
  text-[#313131]  
  font-lato
}
.textsJornada {
  @apply 
  3xl:text-[2.3rem]
  2xl:text-[1.7rem] 
  xl:text-[1.5rem] 
  md:text-[1.2rem] 
  max-md:text-[.8rem] 
  font-bold 
  text-[#313131]  
  font-lato
}
.texts-white {
  @apply 2xl:text-[1.37rem] md:text-[1.37rem] max-md:text-[1.06rem] font-bold text-[#fff]  font-lato
}
.texts-pil {
  @apply 
  3xl:text-[3rem]
  2xl:text-[2rem] 
  md:text-[1.5rem] 
  max-md:text-[1.37rem] 
  max-md:text-center 
  font-black 
  text-[#313131]  
  font-ruda
}
.texts-contador {
  @apply
  3xl:text-[14.5rem] 
  2xl:text-[9.5rem]
  md:text-[7.5rem]  
  max-md:text-[5rem] 
  max-md:text-center
  font-normal 
  text-[#F6802A]  
  font-rubik
}
.texts-bene {
  @apply 
  3xl:text-[3.62rem]
  2xl:text-[2.62rem] 
  1xl:text-[2.62rem]
  md:text-[1.75rem] 
  max-md:text-[1.62rem] 
  max-md:text-center 
  font-normal 
  text-left
  text-[#173D73]  
  font-rubik
}
.title-carousel {
  @apply 3xl:text-[4.37rem] 2xl:text-[2.37rem] md:text-[1.75rem]  max-md:text-[1.37rem] max-md:text-center font-normal text-[#173D73] uppercase font-rubik
}
.title-carousel-text {
  @apply 
  3xl:text-[3rem] /* cuando es mayor a 2560px vale 3rem hasta el infinito*/
  max-2xl:text-[2rem] xl:text-[1.75rem]  /* cuando es mayor a 1280px vale 1.25rem hasta 1536px y de 1536px hasta 2560px vale 1.25rem*/
  mdf:text-[1.10rem]  /* cuando es mayor a 880px vale 1.10rem hasta 1280px*/
  max-mdf:text-[0.87rem] /* cuando es menor a 880px vale .87rem*/
  max-mdf:px-0 
  max-mdf:text-center 
  font-normal 
  md:px-16 px-4
  text-[#313131] 
  font-lato   
}
.title-cuadro-tenis-con-alas {
  @apply
  font-rubik
  text-[#173D73]
  font-normal
  uppercase
  max-lg:text-[1.33rem]
  max-sm:text-[1rem]
  lg:text-[1.33rem]
  xl:text-[2.25rem]
  2xl:text-[2.25rem]
  3xl:text-[3.25rem]
}
.title-cuadro-tenis-con-alas-sub {
  @apply
  3xl:text-[2.8rem]
  2xl:text-[1.8rem]
  xl:text-[1.8rem]
  font-lato
  text-[#173D73]
  font-black
}
.title-sub-becas-aguilas {
  @apply
  3xl:text-[2.8rem]
  2xl:text-[1.75rem]
  mdf:text-[1.25rem]
  font-ruda
  text-[#313131]
  font-black
}
.title-cuadro-becas-aguilas-alas {
  @apply
  max-lg:text-[1.33rem]
  max-sm:text-[1rem]
  lg:text-[1.33rem]
  xl:text-[2.25rem]
  2xl:text-[2.25rem]
  3xl:text-[3.25rem]
  font-ruda
  text-[#173D73]
  font-extrabold
  uppercase
}
.title-cuadro-becas-aguilas-sub {
  @apply
  3xl:text-[2.8rem]
  2xl:text-[1.8rem]
  xl:text-[1.8rem]
  font-rubik
  text-[#173D73]
  font-normal
  uppercase
}
.title-apoyo {
  @apply
  3xl:text-[3.8rem] py-7
  mdf:text-[2.5rem]
  text-center
  font-rubik
  text-[#173D73]
  font-normal
  uppercase
}
.title-titles {
  @apply
  3xl:text-[2.5rem]
  2xl:text-[1.37rem]
  xl:text-[1.37rem]
  text-center
  font-ruda
  text-[#fff]
  font-bold
}
.title-sub-seccion-tec {
  @apply
  3xl:text-[2.8rem]
  2xl:text-[1.75rem]
  mdf:text-[1.5rem]
  font-ruda
  text-[#313131]
  font-black
}
.text-mujeres{
  @apply
  mdf:text-[1rem]
  lg:text-[1.4rem]
  xl:text-[1.8rem]
  3xl:text-[3rem]
  uppercase
  font-rubik
  font-normal
  text-[#173D73]
}
.text-contador-35{
  @apply
  3xl:text-[4.5rem]
  max-2xl:text-[3.25rem]
  xl:text-[3.25rem]
  md:text-[2.25rem]
  text-[#F6802A]
  font-rubik
  font-normal
  text-center
}
.text-de-pesos{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2rem]
  xl:text-[2rem]
  text-[#173D73]
  font-rubik
  font-normal
}
.text-numeros{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2rem]
  xl:text-[2rem]
  text-[#ff6600]
  my-2
  font-rubik
  font-normal
  text-center
}
.text-de-titulos{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2.4rem]
  xl:text-[2rem]
  max-md:text-[1.6rem]
  text-[#173D73]
  font-rubik
  my-2
  font-normal
  uppercase
}
.text-de-parrafo{
  @apply
  3xl:text-[2.4rem]
  max-2xl:text-[2rem]
  xl:text-[2rem]
  text-[#313131]
  font-lato
  text-center
  font-bold
  leading-relaxed
  
}
.text-extraodinario-title{
  @apply
  3xl:text-[5rem]
  max-2xl:text-[3rem]
  xl:text-[3rem]
  max-sm:text-[2rem]
  text-[#fff]
  font-rubik
  uppercase
}
.text-extraodinario-title-sub{
  @apply
  3xl:text-[4.5rem]
  max-2xl:text-[2rem]
  xl:text-[3rem]
  lg:text-[2rem]
  md:text-[1rem]
  max-sm:text-[2rem]
  text-[#fff]
  font-rubik
  uppercase
}
.text-extraodinario-beneficios{
  @apply
  3xl:text-[3.4rem]
  max-2xl:text-[2.2rem]
  xl:text-[2.2rem]
  max-sm:text-[1.75rem]
  text-[#fff]
  font-rubik
  uppercase
  
}
.text-extraodinario-parrafo{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2.75rem]
  xl:text-[1.7rem]
  lg:text-[1.6rem]
  max-sm:text-[1.35rem]
  text-[#fff]
  font-lato
  font-bold
}
.text-extraodinario-parrafo-sub{
  @apply
  3xl:text-[2.3rem]
  2xl:text-[1.7rem]
  lg:text-[1.6rem] 
  md:text-[1.1rem] 
  max-sm:text-[1.35rem]
  text-[#fff]
  font-lato
  font-bold
}
.text-extraodinario-bloque{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2.5rem]
  xl:text-[2.5rem]
  max-sm:text-[1.125rem]
  text-[#173D73]
  font-lato
  font-bold
  
}
.text-extraodinario-bloque-emilio{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[1.5rem]
  xl:text-[1.5rem]
  max-sm:text-[1.125rem]
  text-[#173D73]
  font-rubik
  
}
.text-parallax{
  @apply
  3xl:text-[6rem]
  2xl:text-[4rem]
  xl:text-[3rem]
  lg:text-[2.6rem]
  max-2xl:text-[3rem]
  max-sm:text-[2.5rem]
  drop-shadow-md
  text-[#fff]
  font-rubik
  
}
.text-parallax-parrafo{
  @apply
  3xl:text-[3rem]
  2xl:text-[2.5rem]
  xl:text-[2rem]
  max-sm:text-[1.3rem]
  text-[#313131]
  font-ruda
  font-black
  
}
.text-title-crit{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[1.7rem]
  max-sm:text-[1.35rem]
  text-[#313131]
  font-ruda
  font-black
  
}
.text-title-crit-parrafo{
  @apply
  3xl:text-[2rem]
  max-2xl:text-[1.3rem]
  max-sm:text-[1rem]
  max-sm:text-start
  text-[#313131]
  font-lato
  font-bold
  
}
.text-title-fundaciones-parrafo{
  @apply
  3xl:text-[3rem]
  max-2xl:text-[2rem]
  lg:text-[1.25rem]
  text-[#4E4E4E]
  font-ruda
  font-black
  
}
.text-respaldoca-text{
  @apply
  3xl:text-[2.7rem]
  max-2xl:text-[1.37rem]
  xl:text-[1.37rem]
  max-sm:text-[1.125rem]
  text-[#4E4E4E]
  font-ruda
  font-black
  
}
.text-respaldoca-text-rubik{
  @apply
  3xl:text-[2.7rem]
  max-2xl:text-[1.37rem]
  xl:text-[1.37rem]
  text-[#173D73]
  font-rubik
  uppercase
  
}
.text-footer{
  @apply
  3xl:text-[3.4rem]
  max-2xl:text-[2rem]
  text-[#fff]
  font-rubik
  text-center
  uppercase
  
}
.text-footer-links{
  @apply
  3xl:text-[2.5rem]
  2xl:text-[1rem]
  mdf:text-[.8rem]
  max-mdf:text-[.5rem]
  max-xsm:text-[1rem]
  text-white
  font-rubik
  uppercase
  max-md:w-full
  no-underline
}

.text-footer-derechos{
  @apply
  3xl:text-[2rem]
  max-2xl:text-[0.75rem]
  text-white
  font-lato
  uppercase
  text-xs
  text-center
}
.social-icons {
  @apply
  w-5 h-5 3xl:w-10 3xl:h-10
}
.text-vista-aguila{
  @apply
  sm:text-[1.25rem]
  md:text-[1.5rem]
  xl:text-[1.75rem]
  text-[#093067]
  font-rubik
  uppercase
  max-md:text-start
  max-sm:text-center
}
.boton-css {
  @apply bg-orange-500 hover:bg-orange-600 text-white font-rubik font-medium py-2 px-6 
  3xl:text-[2rem]
  uppercase rounded-3xl inline-block;
}
.text-ballon{
  @apply
  3xl:text-[2.3rem]
  2xl:text-[1.35rem]
  mdf:text-[1.5rem]
  max-mdf:text-[1rem]
  font-rubik
  uppercase
}
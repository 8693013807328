/* MiComponente.css */
.boxTenis {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(18, auto);
}

.boxTenis1 {
    grid-column: 1 / 8;
    grid-row: 1 / 3;
}

.boxTenis2 {
    grid-column: 8 / 17;
    grid-row: 1 / 11;
    z-index: 10;
}

.boxTenis3 {
    grid-column: 1 / 10;
    grid-row: 5;
    z-index: 11;
}

.boxTenis4 {
    grid-column: 1 / 8;
    grid-row: 10 / 19;
}

.boxTenis5 {
    grid-column: 8 / 17;
    grid-row: 11 / 13;
}

.boxTenis6 {
    grid-column: 5 / 17;
    grid-row: 15;
    z-index: 11;
}


.ballonTenis {
    grid-column: 6 / 8;
    grid-row: 1 / 3;
    z-index: 15;
}

.textTenis {
    grid-column: 6 / 8;
    grid-row: 1 / 3;
    z-index: 15;
    place-items: center;
    place-content: center;
    color: white;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
}

.boxWithBallon {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
}

.becasImg {
    grid-column: 1 / 9;
    grid-row: 1 / 9;
    z-index: 10;
}

/* max 880 px in css */
@media (max-width: 880px) {
    .boxTenis {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(18, auto);
        gap: 2rem;
    }

    .boxTenis1 {
        grid-column: 1 / 5;
        grid-row: 1 / 5;
    }
    
    .boxTenis2 {
        grid-column: 1 / 5;
        grid-row: 5 / 9;
    }

    .boxTenis3 {
        grid-column: 1 / 4;
        grid-row: 8 / 10;
        z-index: 11;
    }

    .boxTenis4 {
        grid-column: 1 / 5;
        grid-row: 15 / 19;
    }

    .boxTenis5 {
        grid-column: 1 / 5;
        grid-row: 10 / 15;
    }

    .boxTenis6 {
        grid-column: 2 / 5;
        grid-row: 18;
    }
}

@media (max-width:600px){
    .boxTenis6{
        grid-column: 2 / 5;
        grid-row: 18;
    }
    .boxWithBallon{
        grid-template-rows: repeat(7,1fr);
    }
}

@media (min-width: 2560px) {
    .ballonTenis {
        grid-column: 7 / 8;
        grid-row: 1 / 3;
    }
}
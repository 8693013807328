/* MiComponente.css */
.boxJornada {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(16, auto);
}

.boxJornada1 {
    grid-column: 1 / 5;
    grid-row: 1 / 5;
}

.boxJornada2 {
    grid-column: 5 / 9;
    grid-row: 1 / 13;
}

.boxJornada3 {
    grid-column: 1 / 5;
    grid-row: 7 / 17;
}

.boxJornada4 {
    grid-column: 5 / 9;
    grid-row: 9 / 17;
}

@media (max-width: 769px) {
    .boxJornada {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        gap: 2rem;
    }

    .boxJornada1 {
        grid-column: 1;
        grid-row: 1;
    }
    
    .boxJornada2 {
        grid-column: 1;
        grid-row: 2;
    }

    .boxJornada3 {
        grid-column: 1;
        grid-row: 4;
    }

    .boxJornada4 {
        grid-column: 1;
        grid-row: 3;
    }
}
/* MiComponente.css */
.boxHeader {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, auto);
}

.boxHeader1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.boxHeader2 {
    padding-top: 10%;
    grid-column: 1 / 5;
    grid-row: 6 / 8;
}

.boxHeader3 {
    grid-column: 2 / 9;
    grid-row: 1 / 9;
}
@media (max-width:640px){
    .boxHeader3 {
        grid-column: 1 / 5;
        grid-row: 1 / 4;
    }
}

@media (min-width: 320px) and (max-width: 769px) {
    .boxHeader {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, auto);
        gap: 2rem;
    }

    .boxHeader1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
    
    .boxHeader2 {
        grid-column: 1 / 9;
        grid-row: 8;
        text-align: center;
    }

    .boxHeader3 {
        grid-column: 1 / 9;
        grid-row: 1 / 9;
        margin-bottom: 30px;
    }
}

@media (min-width: 0px) and (max-width: 320px) {
    .boxHeader {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, auto);
        gap: 2rem;
    }

    .boxHeader1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
    
    .boxHeader2 {
        grid-column: 1 / 9;
        grid-row: 8;
        text-align: center;
    }

    .boxHeader3 {
        grid-column: 1 / 9;
        grid-row: 1 / 9;
        margin-bottom: 70px;
    }
}


@media (min-width: 2560px) {
   .logo {
        width: 15rem
   }
}

@media (min-width: 1536px) and (max-width: 2560px)  {
    .logo {
        width: 13rem
   }
}

@media (min-width: 1440px) and (max-width: 1536px) {
    .logo {
        width: 13rem
   }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .logo {
        width: 13rem
   }
}


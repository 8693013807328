/* MiComponente.css */
.boxRespaldo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, auto);
    gap: 1rem;
    justify-content: space-between;
}

.boxRespaldo1 {
    grid-column: 1 / 2;
    grid-row: 1 / 6;
}

.boxRespaldo2 {
    grid-column: 2 / 3;
    grid-row: 1 / 5;
    justify-self: center;
}

.boxRespaldo3 {
    grid-column: 3 / 4;
    grid-row: 1 / 6;
    justify-self: end;
}

/* max 768 px in css */
@media (max-width: 769px) {
    .boxRespaldo {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(6, auto);
        gap: 2.7rem;
        justify-content: space-between;
    }
    
    .boxRespaldo1 {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
        text-align: center;
        z-index: 9;
    }
    
    .boxRespaldo2 {
        grid-column: 5 / 9;
        grid-row: 1 / 3;
        justify-self: end;
        text-align: center;
        z-index: 9;
    }
    
    .boxRespaldo3 {
        grid-column: 2 / 8;
        grid-row: 3 / 6;
        justify-self: center;
        text-align: center;
        z-index: 10;
    }
}
@media (max-width:600px){
    .boxRespaldo1 {
        grid-column: 1 / 9;
        grid-row: 1 / 2;
    }
    
    .boxRespaldo2 {
        grid-column: 1 / 9;
        grid-row: 2 / 3;
        justify-self: center;
    }
    
    .boxRespaldo3 {
        grid-column: 1 / 9;
        grid-row: 3/4;
        justify-self: end;
    }
    
}
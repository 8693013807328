/* MiComponente.css */
.boxEquiposExtraordinarios {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, auto);
}

.boxEquiposExtraordinarios1 {
    grid-column: 1 / 8;
    grid-row: 1 / 4;
    z-index: 11;
}

.boxEquiposExtraordinarios2 {
    grid-column: 8 / 13;
    grid-row: 1 / 6;
    z-index: 10;
}

.boxEquiposExtraordinarios3 {
    grid-column: 7 / 13;
    grid-row: 5 / 8;
    z-index: 11;
}

.boxEquiposExtraordinarios4 {
    grid-column: 1 / 8;
    grid-row: 6 / 13;
}

.boxEquiposExtraordinarios5 {
    grid-column: 8 / 13;
    grid-row: 8 / 13;
}

/* max 768 px in css */
@media (max-width: 769px) {
    .boxEquiposExtraordinarios {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(14, auto);
        gap: 2rem;
    }

    .boxEquiposExtraordinarios1 {
        grid-column: 1 / 5;
        grid-row: 1 / 5;
    }
    
    .boxEquiposExtraordinarios2 {
        grid-column: 1 / 5;
        grid-row: 5 / 9;
    }

    .boxEquiposExtraordinarios3 {
        grid-column: 1 / 4;
        grid-row: 8 / 10;
        z-index: 11;
    }

    .boxEquiposExtraordinarios4 {
        grid-column: 1 / 5;
        grid-row: 15 / 19;
    }

    .boxEquiposExtraordinarios5 {
        grid-column: 1 / 5;
        grid-row: 10 / 15;
    }
}
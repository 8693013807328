/* MiComponente.css */
.boxTecnologica {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, auto);
}

.boxTecnologica1 {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.boxTecnologica2 {
    grid-column: 3 / 5;
    grid-row: 1 / 6;
}

.boxTecnologica3 {
    grid-column: 1 / 3;
    grid-row: 3 / 13;
}

.boxTecnologica4 {
    grid-column: 3 / 5;
    grid-row: 7 / 10;
}

.boxTecnologica5 {
    grid-column: 2 / 4;
    grid-row: 11 / 13;
    z-index: 11;
}

.boxVista {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(12, auto);
}

.boxVista1 {
    grid-column: 1 / 5;
    grid-row: 1 / 6;
}

.boxVista2 {
    grid-column: 5 / 9;
    grid-row: 1 / 9;
}

.boxVista3 {
    grid-column: 1 / 6;
    grid-row: 6;
}

.boxVista4 {
    grid-column: 1 / 9;
    grid-row: 1 / 6;
}

.contraLaViolencia {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, auto);
}

.imagenContraLaViolencia {
    grid-column: 1 / 9;
    grid-row: 1 / 9;
}

.ballonContraLaViolencia {
    grid-column: 1 / 9;
    grid-row: 1 / 9;
}

.violenciaDeGenero {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, auto);
}

.imagenViolenciaDeGenero {
    grid-column: 1 / 9;
    grid-row: 1 / 9;
}

.ballonViolenciaDeGenero {
    grid-column: 7;
    grid-row: 2;
}

/* max 768 px in css */
@media (max-width: 769px) {
    .boxTecnologica {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(7, auto);
        gap: 2.7rem;
    }

    .boxTecnologica1 {
        grid-column: 1 / 5;
        grid-row: 1 / 2;
    }
    
    .boxTecnologica2 {
        grid-column: 1 / 5;
        grid-row: 2 / 4;
    }
    
    .boxTecnologica3 {
        grid-column: 1 / 5;
        grid-row: 6 / 8;
    }
    
    .boxTecnologica4 {
        grid-column: 1 / 5;
        grid-row: 4 / 6;
    }
    
    .boxTecnologica5 {
        grid-column: 2 / 5;
        grid-row: 7;
    }

    .boxVista {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(8, auto);
    }
    
    .boxVista1 {
        grid-column: 1 / 5;
        grid-row: 1 / 4;
    }
    
    .boxVista2 {
        grid-column: 1 / 5;
        grid-row: 4 / 9;
    }
    
    .boxVista3 {
        grid-column: 1 / 5;
        grid-row: 9 ;
        margin-top: -6vh;
    }

    .boxVista4 {
        grid-column: 1 / 5;
        grid-row: 1 / 9;
    }
}

@media (min-width: 769px) and (max-width: 1358px) {
    
    .boxVista3 {
        grid-column: 1 / 9;
        grid-row: 6;
    }
    
}

@media (min-width: 2560px) {
    .ballonContraLaViolencia {
        grid-column: 2 / 9;
        grid-row: 1 / 9;
    }   
}

@media (min-width: 1536px) {
    .ballonContraLaViolencia {
        grid-column: 2 / 9;
        grid-row: 1 / 9;
    }   
}
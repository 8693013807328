.slick-next {
    background: #093067 !important;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  
  .slick-prev {
    background: #093067 !important;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  

  .slick-prev:before,
  .slick-next:before {
    color: #E7E8E8;
    opacity: 1;
  }
  
.ballon {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
  

@media (min-width: 2560px) {
      .ballon {
        width: 280px;
        height: 280px;
      }
}

@media (min-width: 1536px) and (max-width: 2559px)  {
    .ballon {
      width: 200px;
      height: 200px;
    }
}

@media (min-width: 1440px) and (max-width: 1536px) {
  .ballon {
    width: 220px;
    height: 220px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .ballon {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 880px) and (max-width: 1024px) {
  .ballon {
    width: 130px;
    height: 130px;
  }
}

@media (min-width: 428px) and (max-width: 880px) {
  .ballon {
    width: 170px;
    height: 170px;
  }
}

@media (min-width: 0px) and (max-width: 428px) {
  .ballon {
    width: 140px;
    height: 140px;
  }
}